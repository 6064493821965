<template>
	<div class="issueRegister">
		<div class="title">
			<span class="text">问题登记</span>
			<slot name="btn"></slot>
		</div>
		<div class="ycx">
			<span v-html="prompt() "></span>
		</div>
		<div class="content-list">
			<div class="list" v-for="(item, index) in data" :key="index">
				<div class="title-text">{{ item.entryTypeName }}</div>
				<div
					class="content-item"
					v-for="list in item.entryTypeList"
					:key="list.id"
				>
					<div class="content-title">
						{{ list.content }}:
						<span
							style="margin-left: 5px"
							:class="{ abnormal: list.woodenUrl != null }"
							>{{ list.woodenUrl ? "是" : "否" }}</span
						>
						<i v-if="list.woodenUrl" class="el-icon-warning icon-img" style="color:#F56C6C"></i>
						<i v-else class="el-icon-success icon-img" style="color:#47AB40"></i>
					
					</div>
					<div v-if="list.woodenUrl" class="abnormal-item">
						<div class="item">
							<div>
								隐患等级：{{
									list.writeLevel
										? list.writeLevel
										: list.level
								}}级
							</div>
							<div>
								<div>隐患处照片：</div>
								<div class="imgContain">
									<div
										v-for="(item,index) in list.woodenUrl.split(
											','
										)"
										:key="item"
										style="margin-right:10px;"
									>
										<el-image
											class='imgstyle'
											:src="item"
											:preview-src-list="list.woodenUrl.split(',')"
											:z-index='index'
										>
										</el-image>
										<!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :z-index='index' :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
									</div>
								</div>
							</div>
							<div>隐患说明：{{ list.woodenState }}</div>
							<div>
								整改方式：{{
									list.woodenAbarbeitung == 1
										? "用户自行整改"
										: "公司整改"
								}}
							</div>
							<div>
								处理结果：{{
									list.woodenManage == 1||list.woodenManage == 3 ? "未处理" : "已处理"
								}}
							</div>
							<div v-if="list.abarbeitungUrl">
								<div>整改告知单照片：</div>
								<div class="imgContain" >
									<div
										v-for="(img,index) in list.abarbeitungUrl.split(',')"
										:key="img"
										style="margin-right:10px;"
									>
										<el-image
                        					class='imgstyle'
											v-if="img"
											:src="img"
											:preview-src-list="list.abarbeitungUrl.split(',')"
											:z-index='index'
										>
										</el-image>
										<!-- 恢复图片 -->
										<!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :z-index='index' :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
									</div>
								</div>
							</div>
							<div v-if="list.woodenManage == 2">
								<div>处理后照片：</div>
								<div class="imgContain" v-if="list.woodenLaterUrl">
									<div
										v-for="(img,index) in list.woodenLaterUrl.split(
											','
										)"
										:key="img"
										style="margin-right:10px;"
									>
										<el-image
                        					class='imgstyle'
											v-if="img"
											:src="img"
											:preview-src-list="list.woodenLaterUrl.split(',')"
											:z-index='index'
										>
										</el-image>
										<!-- 恢复图片 -->
										<!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :z-index='index' :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
									</div>
								</div>
								<div>
									处理说明：{{ list.processingSpecification }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "issueRegister",
		data() {
			return {
				garyimg: require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`),
			};
		},
		props: {
			data: {
				type: Array,
				default: () => [],
			},
		},
		methods: {
			prompt() {
				let abnormal = 0;
				let dispose = 0;
				let unDispose = 0;
				let company = 0;
				let oneself = 0;
				let unCompany = 0;
				let unOneself = 0;
				let { data } = this;
				data.forEach((el) => {
					el.entryTypeList.forEach((e) => {
						if (e.woodenUrl) abnormal++;
						if (e.woodenManage == 2) dispose++;
						if (e.woodenManage == 2 && e.woodenAbarbeitung == 1)
							oneself++;
						if (e.woodenManage == 2 && e.woodenAbarbeitung == 2)
							company++;
						if (e.woodenManage == 1 || e.woodenManage == 3) unDispose++;
						if ((e.woodenManage == 1 || e.woodenManage == 3) && e.woodenAbarbeitung == 1)
							unOneself++;
						if ((e.woodenManage == 1 || e.woodenManage == 3) && e.woodenAbarbeitung == 2)
							unCompany++;
					});
				});
				return `异常项：${abnormal} 已处理项：${dispose}<span style="color:#333">（用户自行整改：${oneself}，公司整改：${company}）</span>未处理项：${unDispose}<span style="color:#333">（用户自行整改：${unOneself}，公司整改：${unCompany})</span>`;
			},
		},
	};
</script>

<style scoped lang="less">
.ycx{
line-height: 46px;
background: #FFFAF7;
border: 1px solid #F7E1C8;
border-radius: 6px;margin:20px 0;padding-left:15px;color:#FF821D ;
}
	.issueRegister {
		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
			.text {
				font-size: 16px;
				font-weight: bolder;
			}
		}
		.content-list {
			.imgContain {
				display: flex;
				flex-wrap: wrap;
				margin-top:10px;
			}
			.list {
				margin-bottom: 20px;
			}
			.title-text {
				font-size: 14px;
				font-weight: bolder;
				margin-bottom: 5px;
			}
			.content-item {
				margin-bottom: 10px;
				.content-title {
					margin-bottom: 5px;
				}
				.abnormal {
					color: red;
				}
			}
			.abnormal-item {
				padding: 0px;
				.item {
					padding: 10px;
					background: #F4F5F7;line-height: 25px;;
				}
			}
		}
	}
	.icon-img {
		font-size: 15px;margin-left:5px;
	}
	.imgstyle{
		width: 100px;
		height: 100px;
		border: 1px solid #dddddd;
		margin: 5px;
	}
</style>